import React, {useState} from "react";
import Image from "next/legacy/image";
import SummarizeTranslateModal from "@/components/Modal/SummarizeTranslateModal";
import Modal from "@/components/Modal";
import VideoModal from "@/components/Modal/VideoModal";

type VideoPlayerProps = {
  videoSrc: string;
  imgSrc:string;
};

const VideoPlayer = ({videoSrc,imgSrc}:VideoPlayerProps) => {
  const [visibleTranslate, setVisibleTranslate] = useState<boolean>(false);


  return (
    <div className="w-[570px] h-[336px] self-center mt-20">
      <figure className="relative flex p-8 justify-center items-center w-full h-[16.438rem] rounded-xl overflow-hidden">
        <Image
          src={imgSrc}
          // src="https://lokichatdev.blob.core.windows.net/devloki/landing-page/translate.webp"
          alt="Background"
          layout="fill"
          objectFit="cover"
          className="rounded-xl"
          placeholder="blur"
          blurDataURL={`https://lokichatdev.blob.core.windows.net/devloki/landing-page/translate.webp`}
        />
        <div className="w-full flex items-center llg:gap-10 gap-5 llg:flex-row flex-col relative justify-center ">
          <figure className="scale-100 hover:scale-110 duration-300 cursor-pointer">
            <Image
              src="https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png"
              alt="Play button"
              width={130}
              height={130}
              onClick={() => setVisibleTranslate(true)}
              placeholder="blur"
              blurDataURL={`https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png`}
            />
          </figure>
        </div>
      </figure>
      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleTranslate}
        onClose={() => setVisibleTranslate(false)}
        video
      >
        <VideoModal onCancel={() => setVisibleTranslate(false)} video={videoSrc} />
      </Modal>
    </div>
  );
};

export default VideoPlayer
