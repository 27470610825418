import Image from "next/image";
export const Image_gen_footer = ({ img1, img2, img3 }: any) => {
  return (
    <div className="py-20 px-8">
      <div className="flex gap-8 justify-center">
        <figure
          className={`flex justify-center max-w-[1200px] lmd:h-[500px] rounded-3xl overflow-hidden`}
        >
          <Image
            // @ts-ignore
            src={img1}
            width={870}
            height={500}
            placeholder="blur"
            blurDataURL={img1}
            className="w-full h-full rounded-2xl object-cover"
            alt="banner"
          />
        </figure>
        <div className="flex flex-col gap-4">
          <figure
            className={`flex justify-center max-w-[1200px] lmd:h-[240px] rounded-3xl overflow-hidden`}
          >
            <Image
              // @ts-ignore
              src={img2}
              width={420}
              height={240}
              placeholder="blur"
              blurDataURL={img2}
              className="w-full h-full rounded-2xl object-cover"
              alt="banner"
            />
          </figure>
          <figure
            className={`flex justify-center max-w-[1200px] lmd:h-[240px] rounded-3xl overflow-hidden`}
          >
            <Image
              // @ts-ignore
              src={img3}
              width={420}
              height={240}
              placeholder="blur"
              blurDataURL={img3}
              className="w-full h-full rounded-2xl object-cover"
              alt="banner"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};
