import React, { useState, useRef } from "react";

type QuestionProps = {
  question?: string;
  answer?: string;
};

const Questions = ({ question, answer }: QuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200 dark:border-gray-600">
      <button
        onClick={toggleOpen}
        className="flex justify-between items-center w-full p-4 focus:outline-none"
      >
        <span className="text-lg font-medium text-[black] dark:text-white">
          {question}
        </span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-100 ${
            isOpen ? "rotate-45" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>
      </button>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen ? "max-h-[1000px]" : "max-h-0"
        }`}
        style={{
          maxHeight: isOpen ? `${contentRef?.current?.scrollHeight}px` : "0",
        }}
      >
        <div
          className="p-4 text-[black] dark:text-[white]"
          dangerouslySetInnerHTML={{ __html: answer || "" }}
        />
      </div>
    </div>
  );
};

export default Questions;
