import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import carosImg from "../../../../public/images/carousel_img.png";

const UseCase = () => {
  const { t } = useTranslation("translation");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1441 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const imageData = [
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/11.webp",

      desc: t("home_useCase_desc_1"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/12.webp",
      desc: t("home_useCase_desc_2"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/13.webp",
      desc: t("home_useCase_desc_3"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/14.webp",
      desc: t("home_useCase_desc_4"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/15.webp",
      desc: t("home_useCase_desc_5"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/16.webp",
      desc: t("home_useCase_desc_6"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/17.webp",
      desc: t("home_useCase_desc_7"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/18.webp",
      desc: t("home_useCase_desc_8"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/19.webp ",
      desc: t("home_useCase_desc_9"),
    },
    {
      img: "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/20.webp ",
      desc: t("home_useCase_desc_10"),
    },
  ];

  return (
    <div>
      <div className="text-center pb-15 text-4xl font-extrabold">
        {t("home_useCase")}
      </div>
      <div className="h-full sm:h-64 xl:h-full 2xl:h-96  pl-10 ">
        <Carousel responsive={responsive} transitionDuration={500}>
          {imageData.map((src, index) => (
            <div
              key={index}
              className="relative flex items-center justify-center p-2 h-full"
            >
              <div
                className="w-[550px] h-[350px]  2xl:w-[490px] l3xl:w-[600px]  rounded-xl bg-cover bg-center transition-transform duration-500 ease-in-out overflow-auto  "
                style={{
                  backgroundImage: `url(${src.img})`,
                  // height: "100%"
                }}
              >
                <div className="absolute inset-0 flex items-end justify-center pb-6 ">
                  <span className="text-white text-sm font-bold sm:w-[330px] lmd:w-[310px] lxl:w-[450px] l3xl:w-[580px]">
                    {src.desc}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default UseCase;
